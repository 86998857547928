.archive-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}
.archive-text10 {
  color: rgb(255, 255, 255);
  font-size: 32px;
}
.archive-features {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  flex-direction: column;
}
.archive-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.archive-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1400px;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.archive-feature-card1:hover {
  transform: scale(1.02);
}
.archive-navlink10 {
  display: contents;
}
.archive-text11 {
  color: rgb(0, 249, 255);
  margin-bottom: 32px;
  text-decoration: underline;
}
.archive-navlink11 {
  display: contents;
}
.archive-image1 {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 4px;
  text-decoration: none;
}
.archive-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1400px;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.archive-feature-card2:hover {
  transform: scale(1.02);
}
.archive-navlink12 {
  display: contents;
}
.archive-text12 {
  color: rgb(0, 249, 255);
  margin-bottom: 32px;
  text-decoration: underline;
}
.archive-navlink13 {
  display: contents;
}
.archive-image2 {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 4px;
  text-decoration: none;
}
.archive-feature-card3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1400px;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.archive-feature-card3:hover {
  transform: scale(1.02);
}
.archive-navlink14 {
  display: contents;
}
.archive-text13 {
  color: rgb(0, 249, 255);
  margin-bottom: 32px;
  text-decoration: underline;
}
.archive-navlink15 {
  display: contents;
}
.archive-image3 {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 4px;
  text-decoration: none;
}
.archive-feature-card4 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1400px;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.archive-feature-card4:hover {
  transform: scale(1.02);
}
.archive-navlink16 {
  display: contents;
}
.archive-text14 {
  color: rgb(0, 249, 255);
  margin-bottom: 32px;
  text-decoration: underline;
}
.archive-navlink17 {
  display: contents;
}
.archive-image4 {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 4px;
  text-decoration: none;
}
.archive-feature-card5 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1400px;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.archive-feature-card5:hover {
  transform: scale(1.02);
}
.archive-navlink18 {
  display: contents;
}
.archive-text15 {
  color: rgb(0, 249, 255);
  margin-bottom: 32px;
  text-decoration: underline;
}
.archive-navlink19 {
  display: contents;
}
.archive-image5 {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 4px;
  text-decoration: none;
}
.archive-feature-card6 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1400px;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.archive-feature-card6:hover {
  transform: scale(1.02);
}
.archive-navlink20 {
  display: contents;
}
.archive-text16 {
  color: rgb(0, 249, 255);
  margin-bottom: 32px;
}
.archive-navlink21 {
  display: contents;
}
.archive-image6 {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 4px;
  text-decoration: none;
}
.archive-feature-card7 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1400px;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.archive-feature-card7:hover {
  transform: scale(1.02);
}
.archive-navlink22 {
  display: contents;
}
.archive-text19 {
  color: rgb(0, 249, 255);
  margin-bottom: 32px;
}
.archive-navlink23 {
  display: contents;
}
.archive-image7 {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 4px;
  text-decoration: none;
}
.archive-feature-card8 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1400px;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.archive-feature-card8:hover {
  transform: scale(1.02);
}
.archive-navlink24 {
  display: contents;
}
.archive-text22 {
  color: rgb(0, 249, 255);
  margin-bottom: 32px;
}
.archive-navlink25 {
  display: contents;
}
.archive-image8 {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 4px;
  text-decoration: none;
}
@media(max-width: 1600px) {
  .archive-image1 {
    width: 50%;
    height: auto;
  }
  .archive-image2 {
    width: 50%;
    height: auto;
  }
  .archive-image3 {
    width: 50%;
    height: auto;
  }
  .archive-image4 {
    width: 50%;
    height: auto;
  }
  .archive-text15 {
    color: rgb(0, 249, 255);
    text-decoration: underline;
  }
  .archive-image5 {
    width: 50%;
    height: auto;
  }
  .archive-text16 {
    color: rgb(0, 249, 255);
  }
  .archive-image6 {
    width: 50%;
    height: auto;
  }
  .archive-text19 {
    color: rgb(0, 249, 255);
    text-decoration: underline;
  }
  .archive-image7 {
    width: 50%;
    height: auto;
  }
  .archive-text22 {
    color: rgb(0, 249, 255);
    text-decoration: underline;
  }
  .archive-image8 {
    width: 50%;
    height: auto;
  }
}
@media(max-width: 1200px) {
  .archive-feature-card1 {
    max-width: auto;
  }
  .archive-image1 {
    width: auto;
    max-width: auto;
  }
  .archive-feature-card2 {
    max-width: auto;
  }
  .archive-text12 {
    color: rgb(0, 249, 255);
    width: auto;
    height: auto;
  }
  .archive-image2 {
    width: auto;
    height: 120px;
    max-width: auto;
  }
  .archive-feature-card3 {
    max-width: auto;
  }
  .archive-image3 {
    width: auto;
    max-width: auto;
  }
  .archive-feature-card4 {
    max-width: auto;
  }
  .archive-text14 {
    color: rgb(0, 249, 255);
    width: auto;
    height: auto;
    text-decoration: underline;
  }
  .archive-image4 {
    width: auto;
    height: 120px;
    max-width: auto;
  }
  .archive-feature-card5 {
    max-width: auto;
  }
  .archive-text15 {
    color: rgb(0, 249, 255);
    width: auto;
    height: auto;
    text-decoration: underline;
  }
  .archive-image5 {
    width: auto;
    height: 120px;
    max-width: auto;
  }
  .archive-feature-card6 {
    max-width: auto;
  }
  .archive-text16 {
    color: rgb(0, 249, 255);
    width: auto;
    height: auto;
  }
  .archive-image6 {
    width: auto;
    height: 120px;
    max-width: auto;
  }
  .archive-feature-card7 {
    max-width: auto;
  }
  .archive-text19 {
    color: rgb(0, 249, 255);
    width: auto;
    height: auto;
  }
  .archive-image7 {
    width: auto;
    height: 120px;
    max-width: auto;
  }
  .archive-feature-card8 {
    max-width: auto;
  }
  .archive-text22 {
    color: rgb(0, 249, 255);
    width: auto;
    height: auto;
    text-decoration: underline none;
  }
  .archive-image8 {
    width: auto;
    height: 120px;
    max-width: auto;
  }
}
@media(max-width: 767px) {
  .archive-feature-card1 {
    max-width: auto;
  }
  .archive-feature-card2 {
    max-width: auto;
  }
}
@media(max-width: 479px) {
  .archive-features {
    width: 100%;
    max-width: auto;
  }
  .archive-image6 {
    width: 100%;
    height: auto;
  }
  .archive-image7 {
    width: 100%;
    height: auto;
  }
  .archive-image8 {
    width: 100%;
    height: auto;
  }
}
