.games-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.games-services {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.games-service-card1 {
  width: 50%;
  height: auto;
  display: flex;
  align-self: center;
  min-height: auto;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
  justify-content: center;
}
.games-card-content10 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text100 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text101 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link10 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #00ff02;
}
.games-card-content11 {
  width: 437px;
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text102 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text103 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link11 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #ff9800;
}
.games-card-content12 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text107 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text108 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link12 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: rgba(0, 255, 224, 0.65);
}
.games-card-content13 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text109 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text110 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link13 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: rgba(111, 0, 255, 0.65);
}
.games-card-content14 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text111 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text112 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link14 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.games-card-content15 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text113 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text114 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link15 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: rgb(119, 0, 73);
}
.games-card-content16 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text115 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text116 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link17 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #f9753f;
}
.games-card-content17 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text119 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text120 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link18 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: rgb(251, 113, 237);
}
.games-card-content18 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text121 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text122 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link19 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #ef8566;
}
.games-card-content19 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text126 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text127 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link20 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #079404;
}
.games-card-content20 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text131 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text132 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link21 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(315deg, rgb(189, 195, 199) 0.00%,rgb(67, 0, 255) 100.00%);
}
.games-card-content21 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text136 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text137 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link23 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, rgb(100, 179, 244) 0.00%,rgb(244, 100, 100) 45.00%,rgb(244, 100, 100) 61.00%,rgb(100, 179, 244) 100.00%);
}
.games-card-content22 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text144 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text145 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link24 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, rgb(255, 0, 0) 1.00%,rgb(232, 195, 91) 46.00%,rgb(223, 197, 97) 59.00%,rgb(255, 0, 0) 100.00%);
}
.games-card-content23 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text151 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text152 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link25 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, rgb(178, 0, 255) 2.00%,rgb(91, 147, 232) 45.00%,rgb(97, 135, 223) 59.00%,rgb(28, 255, 0) 100.00%);
}
.games-card-content24 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text158 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text159 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link26 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: linear-gradient(45deg, rgb(0, 0, 0) 0.00%,rgb(99, 100, 100) 38.00%,rgb(145, 145, 147) 59.00%,rgb(255, 255, 255) 98.00%);
}
.games-card-content25 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text163 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text164 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link27 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: linear-gradient(0deg, rgb(0, 11, 255) 3.00%,rgb(0, 0, 0) 37.00%,rgb(0, 0, 0) 59.00%,rgb(255, 0, 0) 98.00%);
}
.games-card-content26 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text166 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text167 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link28 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: linear-gradient(45deg, rgb(130, 65, 4) 0.00%,rgb(255, 0, 0) 28.00%,rgb(255, 241, 0) 74.00%,rgb(15, 255, 0) 100.00%,rgb(255, 241, 0) 99.00%);
}
.games-card-content27 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text169 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text170 {
  color: var(--dl-color-primary-700);
  font-size: 18px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link29 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: radial-gradient(circle at center, rgb(255, 0, 0) 0.00%,rgb(0, 0, 0) 99.00%);
}
.games-card-content28 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text172 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text173 {
  color: var(--dl-color-primary-700);
  font-size: 18px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link30 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: radial-gradient(circle at right top, rgb(83, 53, 0) 0.00%,rgb(0, 0, 0) 99.00%);
}
.games-card-content29 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text175 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text176 {
  color: var(--dl-color-primary-700);
  font-size: 18px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link31 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: radial-gradient(circle at center, rgb(255, 33, 0) 1.00%,rgb(0, 0, 0) 100.00%);
}
.games-card-content30 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text178 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text179 {
  color: var(--dl-color-primary-700);
  font-size: 18px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link32 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: linear-gradient(to right, #c02425 0%, #f0cb35 100%);
}
.games-card-content31 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text181 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text182 {
  color: var(--dl-color-primary-700);
  font-size: 18px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link33 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: linear-gradient(90deg, rgb(192, 36, 37) 0.00%,rgb(93, 53, 240) 100.00%);
}
.games-card-content32 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text184 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text185 {
  color: var(--dl-color-primary-700);
  font-size: 18px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link34 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: linear-gradient(90deg, rgb(255, 163, 0) 0.00%,rgb(53, 240, 185) 100.00%,rgb(93, 53, 240) 31.00%,rgb(93, 53, 240) 100.00%);
}
.games-card-content33 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text187 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text188 {
  color: var(--dl-color-primary-700);
  font-size: 18px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link35 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #D9D9D9;
  background-image: linear-gradient(90deg, rgb(64, 46, 13) 0.00%,rgb(255, 255, 255) 48.00%,rgb(64, 46, 13) 100.00%,rgb(64, 46, 13) 100.00%);
}
.games-container2 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-service-card2 {
  width: 100%;
  display: flex;
  min-height: auto;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
}
.games-card-content34 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text190 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text191 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link36 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #ffed00;
}
.games-card-content35 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text192 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text193 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link37 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #ff0000;
}
.games-card-content36 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text194 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text195 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link38 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #c400ff;
}
.games-card-content37 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text198 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text199 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link39 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: rgb(255, 0, 122);
}
.games-card-content38 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text202 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text203 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link40 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #83a004;
}
.games-card-content39 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text206 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text207 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link41 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: rgb(5, 88, 31);
}
.games-card-content40 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text210 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text211 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link42 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #5a1818;
}
.games-card-content41 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text217 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text218 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link43 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(192, 36, 37) 0.00%,rgb(0, 11, 255) 100.00%);
}
.games-card-content42 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text224 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text225 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link44 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(192, 36, 37) 0.00%,rgb(255, 0, 163) 100.00%);
}
.games-card-content43 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text231 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text232 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link45 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(255, 254, 0) 3.00%,rgb(22, 255, 0) 100.00%);
}
.games-card-content44 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text238 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text239 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text241 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link46 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(180deg, rgb(144, 254, 94) 2.00%,rgb(208, 161, 121) 51.00%,rgb(175, 75, 200) 99.00%);
}
.games-card-content45 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text247 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text248 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text250 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link47 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(180deg, rgb(254, 233, 94) 2.00%,rgb(208, 161, 121) 51.00%,rgb(200, 75, 97) 97.00%);
}
.games-card-content46 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text256 {
  color: #ffffff;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text257 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text259 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link48 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(0deg, rgb(255, 76, 231) 0.00%,rgb(255, 39, 39) 98.00%);
}
.games-card-content47 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text265 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text268 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link49 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, rgb(255, 255, 255) 0.00%,rgb(51, 30, 1) 100.00%);
}
.games-card-content48 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text274 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text277 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link50 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(0deg, rgb(255, 0, 0) 1.00%,rgb(221, 221, 221) 100.00%);
}
.games-card-content49 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text283 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text286 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link51 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, rgb(189, 195, 199) 0.00%,rgb(0, 0, 0) 100.00%);
}
.games-card-content50 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text292 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text295 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link52 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, rgb(244, 244, 244) 0.00%,rgb(54, 255, 0) 0.00%,rgb(0, 0, 0) 100.00%,rgb(0, 0, 0) 55.00%);
}
.games-text298 {
  color: #000000;
}
.games-card-content51 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text301 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text304 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link53 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(255, 20, 0) 2.00%,rgb(76, 0, 39) 99.00%);
}
.games-text307 {
  color: #000000;
}
.games-card-content52 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text310 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text313 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link54 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(180deg, rgb(0, 0, 0) 2.00%,rgb(255, 137, 0) 32.00%,rgb(0, 0, 0) 100.00%);
}
.games-text316 {
  color: #000000;
}
.games-card-content53 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text319 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text322 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link55 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, rgb(181, 231, 246) 1.00%,rgb(240, 203, 53) 100.00%);
}
.games-text325 {
  color: #000000;
}
.games-card-content54 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text328 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text331 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link56 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, rgb(255, 0, 0) 0.00%,rgb(137, 170, 3) 97.00%);
}
.games-text334 {
  color: #000000;
}
.games-card-content55 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text337 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text340 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link57 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(225deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.games-text343 {
  color: #000000;
}
.games-card-content56 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
}
.games-text346 {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-text349 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.games-link58 {
  color: var(--dl-color-gray-white);
  width: 100%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(225deg, rgb(254, 94, 94) 1.00%,rgb(199, 208, 121) 50.00%,rgb(200, 75, 75) 99.00%);
}
.games-text352 {
  color: #000000;
}
@media(max-width: 1600px) {
  .games-services {
    padding-top: 0px;
  }
  .games-service-card1 {
    align-self: flex-start;
  }
  .games-text116 {
    color: var(--dl-color-primary-700);
  }
  .games-text118 {
    color: #00f7ff;
    font-size: 16px;
    font-style: normal;
    font-family: Open Sans;
    font-weight: 400;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .games-link16 {
    color: #00f7ff;
  }
  .games-text152 {
    color: var(--dl-color-primary-700);
  }
  .games-link25 {
    background-image: linear-gradient(45deg, rgb(178, 0, 255) 2.00%,rgb(91, 147, 232) 45.00%,rgb(97, 135, 223) 59.00%,rgb(28, 255, 0) 100.00%);
  }
  .games-text159 {
    color: var(--dl-color-primary-700);
    width: auto;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link26 {
    width: 100%;
    background-image: linear-gradient(45deg, rgb(0, 0, 0) 0.00%,rgb(99, 100, 100) 0.00%,rgb(255, 255, 255) 98.00%,rgb(255, 255, 255) 100.00%);
  }
  .games-text164 {
    color: var(--dl-color-primary-700);
    width: auto;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link27 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(0, 11, 255) 3.00%,rgb(0, 0, 0) 37.00%,rgb(0, 0, 0) 59.00%,rgb(255, 0, 0) 98.00%);
  }
  .games-text167 {
    color: var(--dl-color-primary-700);
    width: auto;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link28 {
    width: 100%;
    background-image: linear-gradient(45deg, rgb(130, 65, 4) 0.00%,rgb(255, 0, 0) 28.00%,rgb(255, 241, 0) 74.00%,rgb(15, 255, 0) 100.00%,rgb(255, 241, 0) 99.00%);
  }
  .games-text170 {
    color: var(--dl-color-primary-700);
    width: auto;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link29 {
    width: 100%;
    background-image: radial-gradient(circle at center, rgb(255, 0, 0) 0.00%,rgb(0, 0, 0) 99.00%);
  }
  .games-text173 {
    color: var(--dl-color-primary-700);
    width: auto;
    font-size: 18px;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link30 {
    width: 100%;
    background-image: radial-gradient(circle at right top, rgb(83, 53, 0) 0.00%,rgb(0, 0, 0) 99.00%);
  }
  .games-text176 {
    color: var(--dl-color-primary-700);
    width: auto;
    font-size: 18px;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link31 {
    width: 100%;
    background-image: radial-gradient(circle at center, rgb(255, 33, 0) 1.00%,rgb(0, 0, 0) 100.00%);
  }
  .games-text179 {
    color: var(--dl-color-primary-700);
    width: auto;
    font-size: 18px;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link32 {
    width: 100%;
    background-image: linear-gradient(to right, #c02425 0%, #f0cb35 100%);
  }
  .games-text182 {
    color: var(--dl-color-primary-700);
    width: auto;
    font-size: 18px;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link33 {
    width: 100%;
    background-image: linear-gradient(90deg, rgb(192, 36, 37) 0.00%,rgb(93, 53, 240) 100.00%);
  }
  .games-text185 {
    color: var(--dl-color-primary-700);
    width: auto;
    font-size: 18px;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link34 {
    width: 100%;
    background-image: linear-gradient(90deg, rgb(255, 163, 0) 0.00%,rgb(53, 240, 185) 100.00%,rgb(93, 53, 240) 31.00%,rgb(93, 53, 240) 100.00%);
  }
  .games-text186 {
    color: rgb(0, 0, 0);
  }
  .games-text188 {
    color: var(--dl-color-primary-700);
    width: auto;
    font-size: 18px;
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link35 {
    width: 100%;
    background-image: linear-gradient(90deg, rgb(64, 46, 13) 0.00%,rgb(255, 255, 255) 48.00%,rgb(64, 46, 13) 100.00%,rgb(64, 46, 13) 100.00%);
  }
  .games-text189 {
    color: rgb(0, 0, 0);
  }
  .games-service-card2 {
    width: auto;
  }
  .games-text256 {
    color: rgb(255, 255, 255);
  }
  .games-text259 {
    color: var(--dl-color-primary-700);
  }
  .games-link48 {
    background-image: linear-gradient(0deg, rgb(255, 76, 231) 0.00%,rgb(255, 39, 39) 98.00%);
  }
  .games-link49 {
    background-image: linear-gradient(45deg, rgb(255, 255, 255) 0.00%,rgb(51, 30, 1) 100.00%);
  }
  .games-text274 {
    color: rgb(255, 255, 255);
  }
  .games-text277 {
    color: var(--dl-color-primary-700);
  }
  .games-link50 {
    background-image: linear-gradient(0deg, rgb(255, 0, 0) 1.00%,rgb(221, 221, 221) 100.00%);
  }
  .games-text283 {
    color: rgb(255, 255, 255);
  }
  .games-text286 {
    color: var(--dl-color-primary-700);
  }
  .games-link51 {
    background-color: #D9D9D9;
    background-image: linear-gradient(45deg, rgb(189, 195, 199) 0.00%,rgb(0, 0, 0) 100.00%);
  }
  .games-text292 {
    color: rgb(255, 255, 255);
  }
  .games-text295 {
    color: var(--dl-color-primary-700);
  }
  .games-link52 {
    background-color: #D9D9D9;
    background-image: linear-gradient(45deg, rgb(244, 244, 244) 0.00%,rgb(54, 255, 0) 0.00%,rgb(0, 0, 0) 100.00%,rgb(0, 0, 0) 55.00%);
  }
  .games-text298 {
    color: rgb(0, 0, 0);
  }
  .games-text301 {
    color: rgb(255, 255, 255);
  }
  .games-text304 {
    color: var(--dl-color-primary-700);
  }
  .games-link53 {
    background-color: #D9D9D9;
    background-image: linear-gradient(90deg, rgb(255, 20, 0) 2.00%,rgb(76, 0, 39) 99.00%);
  }
  .games-text307 {
    color: rgb(0, 0, 0);
  }
  .games-text310 {
    color: rgb(255, 255, 255);
  }
  .games-text313 {
    color: var(--dl-color-primary-700);
  }
  .games-link54 {
    background-color: #D9D9D9;
    background-image: linear-gradient(180deg, rgb(0, 0, 0) 2.00%,rgb(255, 137, 0) 32.00%,rgb(0, 0, 0) 100.00%);
  }
  .games-text316 {
    color: rgb(0, 0, 0);
  }
  .games-text319 {
    color: rgb(255, 255, 255);
  }
  .games-text322 {
    color: var(--dl-color-primary-700);
  }
  .games-link55 {
    background-color: #D9D9D9;
    background-image: linear-gradient(45deg, rgb(181, 231, 246) 1.00%,rgb(240, 203, 53) 100.00%);
  }
  .games-text325 {
    color: rgb(0, 0, 0);
  }
  .games-text328 {
    color: rgb(255, 255, 255);
  }
  .games-text331 {
    color: var(--dl-color-primary-700);
  }
  .games-link56 {
    background-color: #D9D9D9;
    background-image: linear-gradient(45deg, rgb(255, 0, 0) 0.00%,rgb(137, 170, 3) 97.00%);
  }
  .games-text334 {
    color: rgb(0, 0, 0);
  }
  .games-text337 {
    color: rgb(255, 255, 255);
  }
  .games-text340 {
    color: var(--dl-color-primary-700);
  }
  .games-link57 {
    background-color: #D9D9D9;
    background-image: linear-gradient(225deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
  }
  .games-text343 {
    color: rgb(0, 0, 0);
  }
  .games-text346 {
    color: rgb(255, 255, 255);
  }
  .games-text349 {
    color: var(--dl-color-primary-700);
  }
  .games-link58 {
    background-color: #D9D9D9;
    background-image: linear-gradient(225deg, rgb(254, 94, 94) 1.00%,rgb(199, 208, 121) 50.00%,rgb(200, 75, 75) 99.00%);
  }
  .games-text352 {
    color: rgb(0, 0, 0);
  }
}
@media(max-width: 1200px) {
  .games-services {
    padding-top: 0px;
  }
  .games-text122 {
    color: var(--dl-color-primary-700);
  }
  .games-link19 {
    background-color: #ef8566;
  }
  .games-text127 {
    color: var(--dl-color-primary-700);
  }
  .games-link20 {
    background-color: #079404;
  }
  .games-text132 {
    color: var(--dl-color-primary-700);
  }
  .games-link21 {
    background-color: #079404;
    background-image: linear-gradient(315deg, rgb(189, 195, 199) 0.00%,rgb(67, 0, 255) 100.00%);
  }
  .games-text133 {
    color: #ffffff;
  }
  .games-text137 {
    color: var(--dl-color-primary-700);
  }
  .games-link22 {
    text-decoration: underline;
  }
  .games-link23 {
    background-color: #079404;
    background-image: linear-gradient(45deg, rgb(100, 179, 244) 0.00%,rgb(244, 100, 100) 45.00%,rgb(244, 100, 100) 61.00%,rgb(100, 179, 244) 100.00%);
  }
  .games-text141 {
    color: rgb(255, 255, 255);
  }
  .games-text145 {
    color: var(--dl-color-primary-700);
  }
  .games-link24 {
    background-color: #079404;
    background-image: linear-gradient(45deg, rgb(255, 0, 0) 1.00%,rgb(232, 195, 91) 46.00%,rgb(223, 197, 97) 59.00%,rgb(255, 0, 0) 100.00%);
  }
  .games-text148 {
    color: rgb(255, 255, 255);
  }
  .games-text152 {
    color: var(--dl-color-primary-700);
  }
  .games-link25 {
    background-color: #079404;
    background-image: linear-gradient(45deg, rgb(178, 0, 255) 2.00%,rgb(91, 147, 232) 45.00%,rgb(97, 135, 223) 59.00%,rgb(28, 255, 0) 100.00%);
  }
  .games-text155 {
    color: rgb(255, 255, 255);
  }
  .games-link26 {
    background-color: #079404;
    background-image: linear-gradient(45deg, rgb(0, 0, 0) 0.00%,rgb(99, 100, 100) 38.00%,rgb(145, 145, 147) 59.00%,rgb(255, 255, 255) 98.00%);
  }
  .games-text160 {
    color: rgb(255, 255, 255);
  }
  .games-text163 {
    color: rgb(255, 255, 255);
  }
  .games-text164 {
    color: var(--dl-color-primary-700);
  }
  .games-link27 {
    background-color: #079404;
    background-image: linear-gradient(0deg, rgb(0, 11, 255) 3.00%,rgb(0, 0, 0) 37.00%,rgb(0, 0, 0) 59.00%,rgb(255, 0, 0) 98.00%);
  }
  .games-text165 {
    color: rgb(255, 255, 255);
  }
  .games-text167 {
    color: var(--dl-color-primary-700);
  }
  .games-link28 {
    background-color: #079404;
    background-image: linear-gradient(45deg, rgb(130, 65, 4) 0.00%,rgb(255, 0, 0) 28.00%,rgb(255, 241, 0) 74.00%,rgb(15, 255, 0) 100.00%,rgb(255, 241, 0) 99.00%);
  }
  .games-text168 {
    color: rgb(255, 255, 255);
  }
  .games-text170 {
    color: var(--dl-color-primary-700);
  }
  .games-link29 {
    background-color: #079404;
    background-image: radial-gradient(circle at center, rgb(255, 0, 0) 0.00%,rgb(0, 0, 0) 99.00%);
  }
  .games-text171 {
    color: rgb(255, 255, 255);
  }
  .games-text173 {
    color: var(--dl-color-primary-700);
  }
  .games-link30 {
    background-color: #079404;
    background-image: radial-gradient(circle at right top, rgb(83, 53, 0) 0.00%,rgb(0, 0, 0) 99.00%);
  }
  .games-text174 {
    color: rgb(255, 255, 255);
  }
  .games-text176 {
    color: var(--dl-color-primary-700);
    font-size: 18px;
  }
  .games-link31 {
    background-color: #079404;
  }
  .games-text177 {
    color: rgb(255, 255, 255);
  }
  .games-text179 {
    color: var(--dl-color-primary-700);
    font-size: 18px;
  }
  .games-link32 {
    background-color: #079404;
    background-image: linear-gradient(to right, #c02425 0%, #f0cb35 100%);
  }
  .games-text180 {
    color: rgb(255, 255, 255);
  }
  .games-text182 {
    color: var(--dl-color-primary-700);
    font-size: 18px;
  }
  .games-link33 {
    background-color: #079404;
    background-image: linear-gradient(90deg, rgb(192, 36, 37) 0.00%,rgb(93, 53, 240) 100.00%);
  }
  .games-text183 {
    color: rgb(255, 255, 255);
  }
  .games-text185 {
    color: var(--dl-color-primary-700);
    font-size: 18px;
  }
  .games-link34 {
    background-color: #079404;
    background-image: linear-gradient(90deg, rgb(255, 163, 0) 0.00%,rgb(53, 240, 185) 100.00%,rgb(93, 53, 240) 31.00%,rgb(93, 53, 240) 100.00%);
  }
  .games-text186 {
    color: #000000;
  }
  .games-text188 {
    color: var(--dl-color-primary-700);
  }
  .games-link35 {
    background-color: #079404;
    background-image: linear-gradient(90deg, rgb(64, 46, 13) 0.00%,rgb(255, 255, 255) 48.00%,rgb(64, 46, 13) 100.00%,rgb(64, 46, 13) 100.00%);
  }
  .games-text189 {
    color: rgb(0, 0, 0);
  }
  .games-service-card2 {
    width: auto;
    min-height: auto;
  }
  .games-text199 {
    color: var(--dl-color-primary-700);
  }
  .games-link39 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: rgb(255, 0, 122);
  }
  .games-text203 {
    color: var(--dl-color-primary-700);
  }
  .games-link40 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #83a004;
  }
  .games-text207 {
    color: var(--dl-color-primary-700);
  }
  .games-link41 {
    color: var(--dl-color-gray-white);
    width: 80%;
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: rgb(5, 88, 31);
  }
  .games-text211 {
    color: var(--dl-color-primary-700);
  }
  .games-link42 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #5a1818;
  }
  .games-text218 {
    color: var(--dl-color-primary-700);
  }
  .games-link43 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #5a1818;
  }
  .games-text225 {
    color: var(--dl-color-primary-700);
  }
  .games-link44 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #5a1818;
    background-image: linear-gradient(90deg, rgb(192, 36, 37) 0.00%,rgb(255, 0, 163) 100.00%);
  }
  .games-text232 {
    color: var(--dl-color-primary-700);
  }
  .games-link45 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #5a1818;
    background-image: linear-gradient(90deg, rgb(255, 254, 0) 3.00%,rgb(22, 255, 0) 100.00%);
  }
  .games-card-content44 {
    max-width: auto;
  }
  .games-text238 {
    color: rgb(255, 255, 255);
  }
  .games-text241 {
    color: var(--dl-color-primary-700);
  }
  .games-link46 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(180deg, rgb(144, 254, 94) 2.00%,rgb(208, 161, 121) 51.00%,rgb(175, 75, 200) 99.00%);
  }
  .games-card-content45 {
    max-width: auto;
  }
  .games-text247 {
    color: rgb(255, 255, 255);
  }
  .games-text250 {
    color: var(--dl-color-primary-700);
  }
  .games-link47 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(180deg, rgb(254, 233, 94) 2.00%,rgb(208, 161, 121) 51.00%,rgb(200, 75, 97) 97.00%);
  }
  .games-card-content46 {
    max-width: auto;
  }
  .games-text256 {
    color: rgb(255, 255, 255);
  }
  .games-text259 {
    color: var(--dl-color-primary-700);
  }
  .games-link48 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(0deg, rgb(255, 76, 231) 0.00%,rgb(255, 39, 39) 98.00%);
  }
  .games-card-content47 {
    max-width: auto;
  }
  .games-text265 {
    color: rgb(255, 255, 255);
  }
  .games-text268 {
    color: var(--dl-color-primary-700);
  }
  .games-link49 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(45deg, rgb(255, 255, 255) 0.00%,rgb(51, 30, 1) 100.00%);
  }
  .games-card-content48 {
    max-width: auto;
  }
  .games-text274 {
    color: rgb(255, 255, 255);
  }
  .games-text277 {
    color: var(--dl-color-primary-700);
  }
  .games-link50 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(0deg, rgb(255, 0, 0) 1.00%,rgb(221, 221, 221) 100.00%);
  }
  .games-card-content49 {
    max-width: auto;
  }
  .games-text283 {
    color: rgb(255, 255, 255);
  }
  .games-text286 {
    color: var(--dl-color-primary-700);
  }
  .games-link51 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(45deg, rgb(189, 195, 199) 0.00%,rgb(0, 0, 0) 100.00%);
  }
  .games-card-content50 {
    max-width: auto;
  }
  .games-text292 {
    color: rgb(255, 255, 255);
  }
  .games-text295 {
    color: var(--dl-color-primary-700);
  }
  .games-link52 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(45deg, rgb(244, 244, 244) 0.00%,rgb(54, 255, 0) 0.00%,rgb(0, 0, 0) 100.00%,rgb(0, 0, 0) 55.00%);
  }
  .games-text298 {
    color: #000000;
  }
  .games-card-content51 {
    max-width: auto;
  }
  .games-text301 {
    color: rgb(255, 255, 255);
  }
  .games-text304 {
    color: var(--dl-color-primary-700);
  }
  .games-link53 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(90deg, rgb(255, 20, 0) 2.00%,rgb(76, 0, 39) 99.00%);
  }
  .games-text307 {
    color: rgb(0, 0, 0);
  }
  .games-card-content52 {
    max-width: auto;
  }
  .games-text310 {
    color: rgb(255, 255, 255);
  }
  .games-text313 {
    color: var(--dl-color-primary-700);
  }
  .games-link54 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(180deg, rgb(0, 0, 0) 2.00%,rgb(255, 137, 0) 32.00%,rgb(0, 0, 0) 100.00%);
  }
  .games-text316 {
    color: rgb(0, 0, 0);
  }
  .games-card-content53 {
    max-width: auto;
  }
  .games-text319 {
    color: rgb(255, 255, 255);
  }
  .games-text322 {
    color: var(--dl-color-primary-700);
  }
  .games-link55 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(45deg, rgb(181, 231, 246) 1.00%,rgb(240, 203, 53) 100.00%);
  }
  .games-text325 {
    color: rgb(0, 0, 0);
  }
  .games-card-content54 {
    max-width: auto;
  }
  .games-text328 {
    color: rgb(255, 255, 255);
  }
  .games-text331 {
    color: var(--dl-color-primary-700);
  }
  .games-link56 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(45deg, rgb(255, 0, 0) 0.00%,rgb(137, 170, 3) 97.00%);
  }
  .games-text334 {
    color: rgb(0, 0, 0);
  }
  .games-card-content55 {
    max-width: auto;
  }
  .games-text337 {
    color: rgb(255, 255, 255);
  }
  .games-text340 {
    color: var(--dl-color-primary-700);
  }
  .games-link57 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(225deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
  }
  .games-text343 {
    color: rgb(0, 0, 0);
  }
  .games-card-content56 {
    max-width: auto;
  }
  .games-text346 {
    color: rgb(255, 255, 255);
  }
  .games-text349 {
    color: var(--dl-color-primary-700);
  }
  .games-link58 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
    background-color: #D9D9D9;
    background-image: linear-gradient(225deg, rgb(254, 94, 94) 1.00%,rgb(199, 208, 121) 50.00%,rgb(200, 75, 75) 99.00%);
  }
  .games-text352 {
    color: rgb(0, 0, 0);
  }
}
@media(max-width: 991px) {
  .games-text163 {
    color: #ffffff;
    font-size: 30px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .games-link29 {
    background-image: radial-gradient(circle at center, rgb(255, 0, 0) 0.00%,rgb(0, 0, 0) 99.00%);
  }
  .games-link30 {
    background-image: radial-gradient(circle at right top, rgb(83, 53, 0) 0.00%,rgb(0, 0, 0) 99.00%);
  }
  .games-link31 {
    background-image: radial-gradient(circle at center, rgb(255, 33, 0) 1.00%,rgb(0, 0, 0) 100.00%);
  }
  .games-link32 {
    background-image: linear-gradient(to right, #c02425 0%, #f0cb35 100%);
  }
  .games-link33 {
    background-image: linear-gradient(90deg, rgb(192, 36, 37) 0.00%,rgb(93, 53, 240) 100.00%);
  }
  .games-text185 {
    color: var(--dl-color-primary-700);
    font-size: 18px;
  }
  .games-link34 {
    background-image: linear-gradient(90deg, rgb(255, 163, 0) 0.00%,rgb(53, 240, 185) 100.00%,rgb(93, 53, 240) 31.00%,rgb(93, 53, 240) 100.00%);
  }
  .games-text186 {
    color: rgb(255, 255, 255);
  }
  .games-text188 {
    color: var(--dl-color-primary-700);
    font-size: 18px;
  }
  .games-link35 {
    background-image: linear-gradient(90deg, rgb(64, 46, 13) 0.00%,rgb(255, 255, 255) 48.00%,rgb(64, 46, 13) 100.00%,rgb(64, 46, 13) 100.00%);
  }
  .games-text189 {
    color: rgb(255, 255, 255);
  }
  .games-text283 {
    color: rgb(255, 255, 255);
  }
  .games-text286 {
    color: var(--dl-color-primary-700);
  }
  .games-link51 {
    background-image: linear-gradient(45deg, rgb(189, 195, 199) 0.00%,rgb(0, 0, 0) 100.00%);
  }
  .games-text292 {
    color: rgb(255, 255, 255);
  }
  .games-text295 {
    color: var(--dl-color-primary-700);
  }
  .games-link52 {
    background-image: linear-gradient(45deg, rgb(244, 244, 244) 0.00%,rgb(54, 255, 0) 0.00%,rgb(0, 0, 0) 100.00%,rgb(0, 0, 0) 55.00%);
  }
  .games-text301 {
    color: rgb(255, 255, 255);
  }
  .games-text304 {
    color: var(--dl-color-primary-700);
  }
  .games-link53 {
    background-image: linear-gradient(90deg, rgb(255, 20, 0) 2.00%,rgb(76, 0, 39) 99.00%);
  }
  .games-text310 {
    color: rgb(255, 255, 255);
  }
  .games-text313 {
    color: var(--dl-color-primary-700);
  }
  .games-link54 {
    background-image: linear-gradient(180deg, rgb(0, 0, 0) 2.00%,rgb(255, 137, 0) 32.00%,rgb(0, 0, 0) 100.00%);
  }
  .games-text319 {
    color: rgb(255, 255, 255);
  }
  .games-text322 {
    color: var(--dl-color-primary-700);
  }
  .games-link55 {
    background-image: linear-gradient(45deg, rgb(181, 231, 246) 1.00%,rgb(240, 203, 53) 100.00%);
  }
  .games-text328 {
    color: rgb(255, 255, 255);
  }
  .games-text331 {
    color: var(--dl-color-primary-700);
  }
  .games-link56 {
    background-image: linear-gradient(45deg, rgb(255, 0, 0) 0.00%,rgb(137, 170, 3) 97.00%);
  }
  .games-text337 {
    color: rgb(255, 255, 255);
  }
  .games-text340 {
    color: var(--dl-color-primary-700);
  }
  .games-link57 {
    background-image: linear-gradient(225deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
  }
  .games-text343 {
    color: rgb(0, 0, 0);
  }
  .games-text346 {
    color: rgb(255, 255, 255);
  }
  .games-text349 {
    color: var(--dl-color-primary-700);
  }
  .games-link58 {
    background-image: linear-gradient(225deg, rgb(254, 94, 94) 1.00%,rgb(199, 208, 121) 50.00%,rgb(200, 75, 75) 99.00%);
  }
  .games-text352 {
    color: rgb(0, 0, 0);
  }
}
@media(max-width: 767px) {
  .games-link32 {
    background-image: linear-gradient(to right, #c02425 0%, #f0cb35 100%);
  }
  .games-link33 {
    background-image: linear-gradient(0deg, rgb(189, 195, 199) 0.00%,rgb(0, 128, 255) 52.00%,rgb(44, 62, 80) 100.00%);
  }
  .games-link34 {
    background-image: linear-gradient(90deg, rgb(255, 163, 0) 0.00%,rgb(53, 240, 185) 100.00%,rgb(93, 53, 240) 31.00%,rgb(93, 53, 240) 100.00%);
  }
  .games-link35 {
    background-image: linear-gradient(90deg, rgb(64, 46, 13) 0.00%,rgb(255, 255, 255) 48.00%,rgb(64, 46, 13) 100.00%,rgb(64, 46, 13) 100.00%);
  }
  .games-link53 {
    background-image: linear-gradient(90deg, rgb(255, 20, 0) 2.00%,rgb(76, 0, 39) 99.00%);
  }
  .games-link54 {
    background-image: linear-gradient(180deg, rgb(0, 0, 0) 2.00%,rgb(255, 137, 0) 32.00%,rgb(0, 0, 0) 100.00%);
  }
  .games-text319 {
    color: rgb(255, 255, 255);
  }
  .games-text322 {
    color: var(--dl-color-primary-700);
  }
  .games-link55 {
    background-image: linear-gradient(45deg, rgb(181, 231, 246) 1.00%,rgb(240, 203, 53) 100.00%);
  }
  .games-text325 {
    color: rgb(0, 0, 0);
  }
  .games-text328 {
    color: rgb(255, 255, 255);
  }
  .games-text331 {
    color: var(--dl-color-primary-700);
  }
  .games-link56 {
    background-image: linear-gradient(45deg, rgb(255, 0, 0) 0.00%,rgb(137, 170, 3) 97.00%);
  }
  .games-text334 {
    color: rgb(0, 0, 0);
  }
  .games-text337 {
    color: rgb(255, 255, 255);
  }
  .games-text340 {
    color: var(--dl-color-primary-700);
  }
  .games-link57 {
    background-image: linear-gradient(225deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
  }
  .games-text343 {
    color: rgb(0, 0, 0);
  }
  .games-text346 {
    color: rgb(255, 255, 255);
  }
  .games-text349 {
    color: var(--dl-color-primary-700);
  }
  .games-link58 {
    background-image: linear-gradient(225deg, rgb(254, 94, 94) 1.00%,rgb(199, 208, 121) 50.00%,rgb(200, 75, 75) 99.00%);
  }
  .games-text352 {
    color: rgb(0, 0, 0);
  }
}
@media(max-width: 479px) {
  .games-card-content10 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-card-content11 {
    width: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-link11 {
    width: auto;
  }
  .games-card-content12 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-card-content13 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-card-content14 {
    width: auto;
    max-width: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-card-content15 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-card-content16 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-link17 {
    color: var(--dl-color-gray-white);
    text-decoration: none;
    background-color: rgb(249, 117, 63);
  }
  .games-card-content17 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-link18 {
    width: auto;
  }
  .games-card-content18 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-link19 {
    width: auto;
  }
  .games-card-content19 {
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .games-link20 {
    width: auto;
  }
  .games-card-content20 {
    margin: 0px;
  }
  .games-link21 {
    width: auto;
  }
  .games-card-content21 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link23 {
    width: auto;
  }
  .games-card-content22 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link24 {
    width: auto;
  }
  .games-card-content23 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link25 {
    width: auto;
  }
  .games-card-content24 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link26 {
    width: auto;
  }
  .games-card-content25 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link27 {
    width: auto;
  }
  .games-card-content26 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link28 {
    width: auto;
  }
  .games-card-content27 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link29 {
    width: auto;
  }
  .games-card-content28 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link30 {
    width: auto;
  }
  .games-card-content29 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link31 {
    width: auto;
  }
  .games-card-content30 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link32 {
    width: auto;
  }
  .games-card-content31 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link33 {
    width: auto;
  }
  .games-card-content32 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link34 {
    width: auto;
  }
  .games-card-content33 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link35 {
    width: auto;
  }
  .games-service-card2 {
    width: 99%;
  }
  .games-card-content34 {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-fourunits);
  }
  .games-link36 {
    width: auto;
  }
  .games-card-content35 {
    margin: 0px;
    padding-right: var(--dl-space-space-fourunits);
  }
  .games-card-content36 {
    padding: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-card-content37 {
    padding: 40px40px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-card-content38 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-link40 {
    width: 100%;
  }
  .games-card-content39 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-link41 {
    width: auto;
  }
  .games-card-content40 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .games-link42 {
    width: auto;
  }
  .games-card-content41 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .games-link43 {
    width: auto;
  }
  .games-card-content42 {
    margin-left: 0px;
    padding-top: 40px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 40px;
  }
  .games-text225 {
    margin-left: var(--dl-space-space-fourunits);
    margin-right: var(--dl-space-space-fourunits);
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link44 {
    width: auto;
  }
  .games-card-content43 {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .games-link45 {
    width: auto;
  }
  .games-card-content44 {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .games-link46 {
    width: auto;
  }
  .games-card-content45 {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .games-link47 {
    width: auto;
  }
  .games-card-content46 {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .games-link48 {
    width: auto;
  }
  .games-card-content47 {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: FiveUnits;
  }
  .games-link49 {
    width: auto;
  }
  .games-card-content48 {
    margin-left: 0px;
    margin-right: 0px;
    padding-right: var(--dl-space-space-fiveunits);
  }
  .games-link50 {
    width: auto;
  }
  .games-card-content49 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-link51 {
    width: auto;
  }
  .games-card-content50 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-text292 {
    color: rgb(255, 255, 255);
  }
  .games-text295 {
    color: var(--dl-color-primary-700);
  }
  .games-link52 {
    width: auto;
  }
  .games-card-content51 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-text301 {
    color: rgb(255, 255, 255);
  }
  .games-text304 {
    color: var(--dl-color-primary-700);
  }
  .games-link53 {
    width: auto;
  }
  .games-card-content52 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-text310 {
    color: rgb(255, 255, 255);
  }
  .games-text313 {
    color: var(--dl-color-primary-700);
  }
  .games-link54 {
    width: auto;
  }
  .games-card-content53 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-text319 {
    color: rgb(255, 255, 255);
  }
  .games-text322 {
    color: var(--dl-color-primary-700);
  }
  .games-link55 {
    width: auto;
  }
  .games-card-content54 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-text328 {
    color: rgb(255, 255, 255);
  }
  .games-text331 {
    color: var(--dl-color-primary-700);
  }
  .games-link56 {
    width: auto;
  }
  .games-card-content55 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-text337 {
    color: rgb(255, 255, 255);
  }
  .games-text340 {
    color: var(--dl-color-primary-700);
  }
  .games-link57 {
    width: auto;
  }
  .games-card-content56 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .games-text346 {
    color: rgb(255, 255, 255);
  }
  .games-text349 {
    color: var(--dl-color-primary-700);
  }
  .games-link58 {
    width: auto;
    background-image: linear-gradient(225deg, rgb(254, 94, 94) 1.00%,rgb(199, 208, 121) 50.00%,rgb(200, 75, 75) 99.00%);
  }
}
