.nov22-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.nov22-text1 {
  color: #ff0000;
}
.nov22-blog-post-card {
  width: 100%;
  height: auto;
  display: flex;
  max-width: auto;
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.nov22-blog-post-card:hover {
  transform: scale(1.02);
}
.nov22-container2 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: space-between;
}
.nov22-container3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.nov22-text2 {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  text-transform: uppercase;
}
.nov22-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.nov22-text3 {
  color: rgb(255, 255, 255);
  margin-bottom: 32px;
}
.nov22-text4 {
  color: var(--dl-color-gray-500);
  font-weight: 300;
}
.nov22-text5 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: 32px;
}
.nov22-container5 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.nov22-profile {
  display: flex;
  align-items: center;
}
.nov22-image {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.nov22-text9 {
  color: #ffffff;
}
@media(max-width: 1600px) {
  .nov22-blog-post-card {
    height: auto;
    max-width: auto;
  }
  .nov22-container2 {
    width: auto;
  }
}
@media(max-width: 1200px) {
  .nov22-container1 {
    background-color: #000000;
  }
  .nov22-blog-post-card {
    height: auto;
    max-width: auto;
  }
}
@media(max-width: 991px) {
  .nov22-blog-post-card {
    height: auto;
    max-width: auto;
  }
  .nov22-container2 {
    width: auto;
  }
}
@media(max-width: 479px) {
  .nov22-pasted-image {
    width: auto;
    height: auto;
  }
  .nov22-text1 {
    width: auto;
    height: auto;
  }
  .nov22-blog-post-card {
    width: auto;
    height: auto;
  }
  .nov22-container2 {
    width: auto;
    height: auto;
  }
  .nov22-text5 {
    width: auto;
    height: auto;
  }
}
