.feb24-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
@media(max-width: 1200px) {
  .feb24-container {
    background-color: #000000;
  }
}
